import { RemoteInterviewOverview } from "@hireroo/app-store/widget/e/RemoteInterviewOverview";
import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";

type EntitySection = Exclude<Widget.RemoteInterviewOverviewProps["followUpQuestionsSection"], undefined>["entityQuestions"][0];

export type GenerateFollowUpQuestionsSectionPropsArgs = {};

export const useGenerateFollowUpQuestionsSectionProps = (
  _args: GenerateFollowUpQuestionsSectionPropsArgs,
): Widget.RemoteInterviewOverviewProps["followUpQuestionsSection"] => {
  const interview = RemoteInterviewOverview.useInterview();
  const lang = useLanguageCode();

  return {
    entityQuestions: (interview.entity?.liveCoding?.sessions || []).reduce<EntitySection[]>((all, session) => {
      if (!session) {
        return all;
      }
      // Skip not inherited sessions because there are no followUpQuestions
      if (!session.isInherited) {
        return all;
      }
      const questions: EntitySection["questions"] = session.followUpQuestions.map((followUpQuestion): EntitySection["questions"][0] => ({
        question: resolveLanguage(followUpQuestion, lang, "question"),
        answer: resolveLanguage(followUpQuestion, lang, "modelAnswer"),
        purpose: resolveLanguage(followUpQuestion, lang, "purpose"),
      }));

      if (session.algorithmQuestion) {
        return all.concat({
          title: resolveLanguage(session.algorithmQuestion, lang, "title"),
          questions: questions,
        });
      } else if (session.systemDesignQuestion) {
        return all.concat({
          title: resolveLanguage(session.systemDesignQuestion, lang, "title"),
          questions: questions,
        });
      }
      return all;
    }, []),
  };
};
