import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

import FollowUpQuestion, { FollowUpQuestionProps } from "../../../../modules/FollowUpQuestion/FollowUpQuestion";

export type FollowUpEntitySectionProps = {
  title: string;
  questions: FollowUpQuestionProps[];
};

const FollowUpEntitySection: React.FC<FollowUpEntitySectionProps> = props => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography mb={2} fontSize={14} sx={{ fontWeight: "bold" }}>
        {props.title}
      </Typography>
      {props.questions.length === 0 && (
        <Typography fontSize={14} color="textSecondary">
          {t("深堀り質問がありません。")}
        </Typography>
      )}
      {props.questions.map((question, index) => (
        <FollowUpQuestion key={`question-${index}`} {...question} />
      ))}
    </Box>
  );
};

FollowUpEntitySection.displayName = "FollowUpEntitySection";

export default FollowUpEntitySection;
