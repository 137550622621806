import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import FollowUpEntitySection, { FollowUpEntitySectionProps } from "../FollowUpEntitySection/FollowUpEntitySection";

const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: "16px",
  backgroundColor: theme.palette["Background/Paper"].p1,
  padding: theme.spacing(2),
}));

export type FollowUpQuestionsSectionProps = {
  entityQuestions: FollowUpEntitySectionProps[];
};

const FollowUpQuestionsSection: React.FC<FollowUpQuestionsSectionProps> = props => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography mb={2} fontSize={16} sx={{ fontWeight: "bold" }}>
        {t("深堀り質問")}
      </Typography>

      <StyledBox>
        {props.entityQuestions.length === 0 && (
          <Typography color="text.secondary" variant="body2" sx={{ textAlign: "center" }}>
            {t("深堀り質問がありません。")}
          </Typography>
        )}
        <Stack spacing={3}>
          {props.entityQuestions.map((question, index) => (
            <FollowUpEntitySection key={`entity-${index}`} {...question} />
          ))}
        </Stack>
      </StyledBox>
    </Box>
  );
};

FollowUpQuestionsSection.displayName = "FollowUpQuestionsSection";

export default FollowUpQuestionsSection;
